import { useEffect } from 'react';

const useTitle = (...titles) => {
  useEffect(() => {
    const previousTitle = window.document.title;

    window.document.title =
      titles.filter(Boolean).length > 0
        ? `${titles.join(' · ')} · Autobedrijf Decleir`
        : 'Autobedrijf Decleir';

    return () => {
      window.document.title = previousTitle;
    };
  }, [titles]);
};

export default useTitle;
