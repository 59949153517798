import React from "react";
import c from "../utils/c";

const Button = ({ className, disabled, children, isIcon, ...props }) => {
  return (
    <button
      disabled={disabled}
      className={c(
        className,
        isIcon ? "px-2" : "px-3",
        "text-sm py-1 rounded-md border border-gray-300",
        disabled && "opacity-50 cursor-not-allowed"
      )}
      {...props}
    >
      {children}
    </button>
  );
};

export default Button;
