import React, { useEffect, useMemo, useState } from "react";

import { format } from "date-fns";
import nlBE from "date-fns/locale/nl-BE";

import useFormatMessage from "../../hooks/useFormatMessage";
import calculate from "../../utils/activities/calculate";
import { ACTIVITY_TYPES } from "../../utils/activities/createSummaryTable";
import useTitle from "../../hooks/useTitle";
import DecimalInput from "../../components/DecimalInput";
import c from "../../utils/c";
import Seniority from "./partial/Seniority";
import DraftWorkedHours from "./partial/DraftWorkedHours";
import ExportButton from "../../components/ExportButton";
import Spinner from "../../components/Spinner";

const defaultWeek = {
  0: 0,
  1: 8,
  2: 8,
  3: 8,
  4: 8,
  5: 8,
  6: 0,
};

const EmployeeDetail = ({
  year,
  monthIndex,
  setMonthIndex,
  canEdit,
  employee,
  employeeActivities,
  employeeYear,
  employeePaidOvertime,
  employeeNotes,
  updatePaidOvertime,
  updateNote,
  createOrUpdateActivity,
}) => {
  const t = useFormatMessage();

  useTitle(`${employee.first_name} ${employee.last_name}`);

  const defaultNumberOfWorkingHoursInDay =
    JSON.parse(employee.default_week) || defaultWeek;

  const [draftPaid, setDraftPaid] = useState("");
  const [draftNote, setDraftNote] = useState("");

  const previousMonth = () => setMonthIndex((m) => Math.max(m - 1, 0));
  const nextMonth = () => setMonthIndex((m) => Math.min(m + 1, 11));

  const data = useMemo(() => {
    return calculate(
      year,
      employeeActivities,
      employeePaidOvertime,
      employeeNotes,
      {
        [ACTIVITY_TYPES.VERLOF]: employeeYear[ACTIVITY_TYPES.VERLOF],
        [ACTIVITY_TYPES.ADV]: employeeYear[ACTIVITY_TYPES.ADV],
        [ACTIVITY_TYPES.OVERUREN]: employeeYear[ACTIVITY_TYPES.OVERUREN],
      }
    );
  }, [
    year,
    employeeActivities,
    employeePaidOvertime,
    employeeNotes,
    employeeYear,
  ]);

  const employedAt = new Date(employee.employed_at);

  const {
    activities,
    start,
    end,
    monthBalance,
    beforeMonthBalance,
    afterMonthBalance,
    paidOvertime,
    note,
  } = data[monthIndex];

  useEffect(() => {
    setDraftPaid(paidOvertime);
  }, [paidOvertime, employee.id, monthIndex]);

  useEffect(() => {
    setDraftNote(note || "");
  }, [note, employee.id, monthIndex]);

  return (
    <div className="w-full print:-m-8">
      <div className="p-8 text-3xl border-b border-gray-200 font-semibld">
        {employee.first_name} {employee.last_name}
      </div>
      <div className="px-8 py-4 w-full flex flex-wrap border-b border-gray-200">
        <div className="w-1/2">
          <div className="font-medium text-gray-600">
            {t("employee-detail.label.joint_committee")}
          </div>
          <div>{employee.joint_committee}</div>
        </div>

        <div className="mt-2 w-1/2">
          <div className="font-medium text-gray-600">
            {t("employee-detail.label.type")}
          </div>
          <div className="capitalize">{employee.type}</div>
        </div>

        <div className="mt-2 w-1/2">
          <div className="font-medium text-gray-600">
            {t("employee-detail.label.employed_at")}
          </div>
          <div>{format(employedAt, "dd/MM/yyyy", { locale: nlBE })}</div>
        </div>

        <div className="mt-2 w-1/2">
          <div className="font-medium text-gray-600">
            {t("employee-detail.label.seniority")}
          </div>
          <div>
            <Seniority employedAt={employedAt} until={end} />
          </div>
        </div>
      </div>
      <div className="w-full flex items-center justify-between px-8 py-4 border-b border-gray-200">
        <div>
          <div className="text-2xl">
            <span className="capitalize font-semibold">
              {format(start, "MMMM", { locale: nlBE })}{" "}
            </span>
            <span>{format(start, "yyyy", { locale: nlBE })}</span>
          </div>
          <div className="text-xs">
            {format(start, "dd/LL", { locale: nlBE })} -{" "}
            {format(end, "dd/LL", { locale: nlBE })}
          </div>
        </div>
        <div className="flex print:hidden">
          <div className="flex items-center">
            <button
              className="text-sm p-1 w-8 h-8 rounded-md border border-gray-300"
              onClick={previousMonth}
              disabled={monthIndex === 0}
              aria-label={t("employee-detail.range.previous")}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
            <button
              className="text-sm py-1 px-3 h-8 ml-2 mr-2 rounded-md border border-gray-300"
              onClick={() => setMonthIndex(new Date().getMonth())}
            >
              {t("employee-detail.range.today")}
            </button>
            <button
              className="text-sm p-1 w-8 h-8 rounded-md border border-gray-300"
              onClick={nextMonth}
              aria-label={t("employee-detail.range.next")}
              disabled={monthIndex === 11}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </div>
          <div className="ml-3">
            <ExportButton
              isIcon={true}
              className="w-8 h-8"
              employeeId={employee.id}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                />
              </svg>
            </ExportButton>
          </div>
        </div>
      </div>
      <div className="w-full flex flex-wrap gap-8 p-8 border-b border-gray-200">
        <table className="border text-sm">
          <thead className="border">
            <tr className="divide-x divide-gray-200">
              <th className="px-2 py-1">
                {t("employee-detail.balances.label")}
              </th>
              <th className="px-2 py-1">
                {t("employee-detail.balances.befor_month")}
              </th>
              <th className="px-2 py-1">
                {t("employee-detail.balances.after_month")}
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {[
              ACTIVITY_TYPES.VERLOF,
              ACTIVITY_TYPES.ADV,
              ACTIVITY_TYPES.OVERUREN,
            ].map((activityType) => (
              <tr key={activityType} className="divide-x divide-gray-200">
                <td className="px-2 py-1">
                  {t(`activity-types.${activityType}`)}
                </td>
                <td
                  className={c(
                    "px-2 py-1 text-center",
                    beforeMonthBalance[activityType] < 0 && "text-red-600"
                  )}
                >
                  {beforeMonthBalance[activityType].toFixed(2)}
                </td>
                <td
                  className={c(
                    "px-2 py-1 text-center",
                    afterMonthBalance[activityType] < 0 && "text-red-600"
                  )}
                >
                  {afterMonthBalance[activityType].toFixed(2)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <table className="border text-sm">
          <thead className="border">
            <tr className="divide-x divide-gray-200">
              <th className="px-2 py-1" colSpan="2">
                Overuren
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            <tr className="divide-x divide-gray-200">
              <td className="px-2 py-1">Uitbetaald deze maand</td>
              <td className="px-2 py-1">
                <DecimalInput
                  className="p-0 border border-gray-300 text-sm mr-2"
                  type="number"
                  value={draftPaid}
                  onChange={(e) => setDraftPaid(e.target.value)}
                />

                {canEdit && (
                  <button
                    className="text-sm px-2 border border-gray-300"
                    onClick={() => {
                      updatePaidOvertime.mutate({
                        employee_id: employee.id,
                        date: format(start, "yyyy-LL-dd", { locale: nlBE }),
                        hours_paid: draftPaid,
                      });
                    }}
                  >
                    wijzig
                  </button>
                )}
              </td>
            </tr>
            <tr className="divide-x divide-gray-200">
              <td className="px-2 py-1">Uitbetaald per jaar (max. 130)</td>
              <td className="px-2 py-1">
                {paidOvertime +
                  beforeMonthBalance[ACTIVITY_TYPES.OVERUREN_BETAALD]}
              </td>
            </tr>
          </tbody>
        </table>

        <table className="border text-sm mr-8">
          <thead className="border">
            <tr className="divide-x divide-gray-200">
              <th className="px-2 py-1" colSpan="2">
                Opmerking
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="px-2 py-1">
                <textarea
                  className="focus:outline-none w-64 border flex"
                  value={draftNote}
                  onChange={(e) => setDraftNote(e.target.value)}
                />
              </td>
              <td className="px-2 py-1 align-bottom">
                {canEdit && (
                  <button
                    className="text-sm px-2 border border-gray-300"
                    onClick={() => {
                      updateNote.mutate({
                        employee_id: employee.id,
                        date: format(start, "yyyy-LL-dd", { locale: nlBE }),
                        note: draftNote,
                      });
                    }}
                  >
                    wijzig
                  </button>
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="w-full p-8 overflow-y-auto">
        <table className="w-full table-fixed text-xs text-center border-t border-b border-gray-200">
          <tbody className="divide-y divide-gray-200">
            <tr className="divide-x divide-gray-200 border-l border-r border-gray-200">
              <th className="w-32 text-left px-2 whitespace-nowrap"></th>
              {activities.map((activity) => (
                <th className="w-12" key={activity.date}>
                  <span className="tabular-nums">
                    {format(activity.date, "dd", { locale: nlBE })}
                  </span>
                  <br />
                  {format(activity.date, "EEEEEE", { locale: nlBE })}
                </th>
              ))}
              <td></td>
            </tr>

            <tr className="divide-x divide-gray-200 border-b border-l border-r border-gray-200">
              <th className="text-left px-2 whitespace-nowrap">Type</th>
              {activities.map((activity) => (
                <td key={activity.date} className="w-12 tabular-nums">
                  <select
                    className="appearance-none px-2 bg-transparent text-center"
                    value={activity.type || ""}
                    onChange={(e) =>
                      createOrUpdateActivity.mutate({
                        employee_id: employee.id,
                        type: e.target.value,
                        date: format(activity.date, "yyyy-LL-dd", {
                          locale: nlBE,
                        }),
                        number_of_working_hours_in_day:
                          activity.number_of_working_hours_in_day ||
                          defaultNumberOfWorkingHoursInDay[
                            activity.date.getDay()
                          ] ||
                          0,
                        number_of_worked_hours:
                          activity.number_of_worked_hours || 0,
                      })
                    }
                  >
                    <option value="" disabled>
                      -
                    </option>
                    <option value={ACTIVITY_TYPES.GEWERKT}>G</option>
                    <option value={ACTIVITY_TYPES.ADV}>A</option>
                    <option value={ACTIVITY_TYPES.VERLOF}>V</option>
                    <option value={ACTIVITY_TYPES.ZIEKTE}>Z</option>
                    <option value={ACTIVITY_TYPES.FEESTDAG}>F</option>
                  </select>
                </td>
              ))}
              <td></td>
            </tr>

            <tr className="divide-x divide-gray-200 border-l border-r border-gray-200">
              <th className="text-left px-2 whitespace-nowrap">
                Aantal werkuren
              </th>
              {activities.map((activity) => (
                <td key={activity.date} className="w-12 tabular-nums">
                  {activity.type && (
                    <DraftWorkedHours
                      employee={employee}
                      value={activity.number_of_working_hours_in_day}
                      className="bg-transparent w-full outline-none text-center"
                      onChange={(value) => {
                        createOrUpdateActivity.mutate({
                          employee_id: employee.id,
                          type: activity.type,
                          date: format(activity.date, "yyyy-LL-dd", {
                            locale: nlBE,
                          }),
                          number_of_working_hours_in_day: value,
                          number_of_worked_hours:
                            activity.number_of_worked_hours,
                        });
                      }}
                    />
                  )}
                </td>
              ))}
              <td></td>
            </tr>

            <tr className="divide-x divide-gray-200 border-b border-l border-r border-gray-200 bg-gray-100">
              <th className="text-left px-2 whitespace-nowrap">
                Gepresteerde uren
              </th>
              {activities.map((activity) => (
                <td key={activity.date} className="w-12 tabular-nums px-2">
                  {activity.type === ACTIVITY_TYPES.GEWERKT && (
                    <DraftWorkedHours
                      employee={employee}
                      value={activity.number_of_worked_hours}
                      className="bg-transparent w-full outline-none text-center"
                      onChange={(value) => {
                        createOrUpdateActivity.mutate({
                          employee_id: employee.id,
                          type: activity.type,
                          date: format(activity.date, "yyyy-LL-dd", {
                            locale: nlBE,
                          }),
                          number_of_working_hours_in_day:
                            activity.number_of_working_hours_in_day,
                          number_of_worked_hours: value,
                        });
                      }}
                    />
                  )}
                </td>
              ))}
              <td></td>
            </tr>

            <tr className="">
              <th className="text-left px-2 whitespace-nowrap"></th>
              {activities.map((activity) => (
                <td key={activity.date} className="tabular-nums py-2"></td>
              ))}
              <td></td>
            </tr>

            <tr className="">
              <th className="text-left px-2 whitespace-nowrap"></th>
              {activities.map((activity) => (
                <td key={activity.date} className="tabular-nums py-2"></td>
              ))}
              <td className="border text-xs font-bold">TOT</td>
            </tr>

            <tr className="divide-x divide-gray-200 border-l border-r border-gray-200">
              <th className="text-left px-2 whitespace-nowrap">Overuren</th>
              {activities.map((activity) => (
                <td key={activity.date} className="w-12 tabular-nums">
                  {activity[ACTIVITY_TYPES.OVERUREN]?.toFixed(2) || 0}
                </td>
              ))}
              <td className="tabular-nums font-bold">
                {monthBalance[ACTIVITY_TYPES.OVERUREN]?.toFixed(2) || 0}
              </td>
            </tr>
            <tr className="divide-x divide-gray-200 border-l border-r border-gray-200">
              <th className="text-left px-2 whitespace-nowrap">(A)DV</th>
              {activities.map((activity) => (
                <td key={activity.date} className="w-12 tabular-nums">
                  {activity[ACTIVITY_TYPES.ADV]?.toFixed(2) || 0}
                </td>
              ))}
              <td className="tabular-nums font-bold">
                {monthBalance[ACTIVITY_TYPES.ADV]?.toFixed(2) || 0}
              </td>
            </tr>
            <tr className="divide-x divide-gray-200 border-l border-r border-gray-200">
              <th className="text-left px-2 whitespace-nowrap">(V)erlof</th>
              {activities.map((activity) => (
                <td key={activity.date} className="w-12 tabular-nums">
                  {activity[ACTIVITY_TYPES.VERLOF]?.toFixed(2) || 0}
                </td>
              ))}
              <td className="tabular-nums font-bold">
                {monthBalance[ACTIVITY_TYPES.VERLOF]?.toFixed(2) || 0}
              </td>
            </tr>
            <tr className="divide-x divide-gray-200 border-l border-r border-gray-200">
              <th className="text-left px-2 whitespace-nowrap">(Z)iekte</th>
              {activities.map((activity) => (
                <td key={activity.date} className="w-12 tabular-nums">
                  {activity[ACTIVITY_TYPES.ZIEKTE]?.toFixed(2) || 0}
                </td>
              ))}
              <td className="tabular-nums font-bold">
                {monthBalance[ACTIVITY_TYPES.ZIEKTE]?.toFixed(2) || 0}
              </td>
            </tr>
            <tr className="divide-x divide-gray-200 border-l border-r border-gray-200">
              <th className="text-left px-2 whitespace-nowrap">(F)eestdag</th>
              {activities.map((activity) => (
                <td key={activity.date} className="w-12 tabular-nums">
                  {activity[ACTIVITY_TYPES.FEESTDAG]?.toFixed(2) || 0}
                </td>
              ))}
              <td className="tabular-nums font-bold">
                {monthBalance[ACTIVITY_TYPES.FEESTDAG]?.toFixed(2) || 0}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="mb-8"></div>
    </div>
  );
};

export default EmployeeDetail;
