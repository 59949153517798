import { format } from "date-fns";
import debounce from "lodash.debounce";
import nlBE from "date-fns/locale/nl-BE";
import React, { useCallback, useEffect, useRef, useState } from "react";
import DecimalInput from "../../../components/DecimalInput";

function useDebounce(value, delay) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );

  return debouncedValue;
}

const DraftWorkedHours = ({ employee, value: _value, className, onChange }) => {
  const [value, _setValue] = useState(_value || 0);
  const debouncedValue = useDebounce(value, 300);

  const delayedSet = useCallback(
    debounce((q) => onChange(q), 500),
    [onChange]
  );

  const setValue = (v) => {
    _setValue(v);
    delayedSet(v);
  };

  useEffect(() => {
    _setValue(_value);
  }, [_value]);

  return (
    <DecimalInput
      className={className}
      value={value}
      onChange={(_, value) => setValue(value)}
    />
  );
};

export default DraftWorkedHours;
