import React from "react";

const DecimalInput = ({ onChange, ...props }) => {
  return (
    <input
      {...props}
      onChange={(e) => {
        onChange(e, e.target.value.replace(",", "."));
      }}
    />
  );
};

DecimalInput.defaultProps = {
  onChange: () => {},
};

export default DecimalInput;
