import React from "react";
import { useMutation, useQuery } from "react-query";
import useActiveYear from "../../hooks/useActiveYear";
import { api } from "../../utils/api";
import EmployeeDetail from "./EmployeeDetail";

const EmployeeDetailContainer = ({ employeeId, canEdit }) => {
  const { year, monthIndex, setMonthIndex } = useActiveYear();

  const { data: employee } = useQuery(`/employees/${employeeId}`, {
    enabled: !!employeeId,
  });

  const { data: activities, refetch: refetchActivities } = useQuery(
    `/employees/${employeeId}/activities?filter[year]=${year}`,
    {
      enabled: !!employeeId,
    }
  );

  const { data: employeePaidOvertime, refetch: refetchPaidOvertime } = useQuery(
    `/employees/${employeeId}/paid-overtime?filter[year]=${year}`,
    {
      enabled: !!employeeId,
    }
  );

  const { data: employeeNotes, refetch: refetchNotes } = useQuery(
    `/employees/${employeeId}/notes?filter[year]=${year}`,
    {
      enabled: !!employeeId,
    }
  );

  const { data: employeeYear } = useQuery(
    `/employees/${employeeId}/years/${year}`,
    {
      enabled: !!employeeId,
    }
  );

  const updatePaidOvertime = useMutation((paidOvertime) => {
    api
      .post(`/employees/${paidOvertime.employee_id}/paid-overtime`, {
        body: paidOvertime,
      })
      .then(() => refetchPaidOvertime());
  });

  const updateNote = useMutation((note) => {
    api
      .post(`/employees/${note.employee_id}/notes`, {
        body: note,
      })
      .then(() => refetchNotes());
  });

  const createOrUpdateActivity = useMutation((body) => {
    api
      .post(`/activities`, {
        body,
      })
      .then(() => refetchActivities());
  });

  if (
    !employee ||
    !activities ||
    !employeeYear ||
    !employeePaidOvertime ||
    !employeeNotes
  ) {
    return null;
  }

  return (
    <EmployeeDetail
      year={year}
      monthIndex={monthIndex}
      setMonthIndex={setMonthIndex}
      canEdit={canEdit}
      employee={employee}
      employeeActivities={activities}
      employeeYear={employeeYear}
      employeePaidOvertime={employeePaidOvertime}
      employeeNotes={employeeNotes}
      updatePaidOvertime={updatePaidOvertime}
      updateNote={updateNote}
      createOrUpdateActivity={createOrUpdateActivity}
    />
  );
};

export default EmployeeDetailContainer;
