import React from "react";
import { Link } from "react-router-dom";

import useFormatMessage from "../../hooks/useFormatMessage";
import AsideItem from "./partial/AsideItem";
import EmployeeDetailContainer from "../EmployeeDetail/EmployeeDetailContainer";

const Employees = ({ employees, year, employeeId, filter, setFilter }) => {
  const t = useFormatMessage();

  return (
    <div className="h-full flex-1 relative z-0 flex overflow-hidden">
      <main
        className="flex-1 relative z-0 overflow-y-auto focus:outline-none order-last"
        tabIndex={0}
      >
        <EmployeeDetailContainer
          year={year}
          employeeId={employeeId}
          canEdit={true}
        />
      </main>
      <aside className="order-first flex flex-col flex-shrink-0 w-96 border-r border-gray-200 print:hidden">
        <div className="px-6 pt-6 pb-4">
          <h2 className="text-lg font-medium text-gray-900">
            {t("employees.title")}
          </h2>
          <p className="mt-1 text-sm text-gray-600">
            {t("employees.title.search")}
          </p>
          <div className="mt-6 flex space-x-4">
            <div className="flex-1 min-w-0">
              <label htmlFor="search" className="sr-only">
                Search
              </label>
              <div className="relative rounded-md border">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <svg
                    className="h-5 w-5 text-gray-400"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
                <input
                  type="search"
                  name="search"
                  id="search"
                  className="block w-full pl-10 py-1 outline-none sm:text-sm border-gray-300 rounded-md"
                  placeholder="Search"
                  value={filter}
                  onChange={(e) => setFilter(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
        <nav
          className="flex-1 min-h-0 relative overflow-y-auto"
          aria-label="Directory"
        >
          <ul className="border-t border-gray-200 relative z-0 divide-y divide-gray-200">
            {employees.map((employee) => (
              <li key={employee.id}>
                <Link to={`/employees/${employee.id}`}>
                  <AsideItem employee={employee} />
                </Link>
              </li>
            ))}
          </ul>
        </nav>
      </aside>
    </div>
  );
};

export default Employees;
