import { useState } from "react";
import { IntlProvider } from "react-intl";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route,
} from "react-router-dom";
import Header from "./components/Header";

import EmployeesContainer from "./pages/Employees/EmployeesContainer";
import ExportContainer from "./pages/Export/ExportContainer";
import Login from "./pages/Login/Login";
import ActiveYearProvider from "./providers/ActiveYearProvider";
import { api, getToken } from "./utils/api";

const defaultQueryFn = ({ queryKey }) => {
  return api.get(queryKey[0]);
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      queryFn: defaultQueryFn,
    },
  },
});

function App() {
  const [isPossiblySignedIn, setIsPossiblySignedIn] = useState(!!getToken());

  return (
    <Router>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <IntlProvider locale="nl">
          <ActiveYearProvider>
            <div className="h-screen print:h-auto flex">
              {isPossiblySignedIn && (
                <div className="w-full h-full overflow-hidden print:overflow-auto flex flex-col">
                  <Header />
                  <div className="flex-grow overflow-hidden print:overflow-auto ">
                    <Switch>
                      <Route exact path="/employees/:employeeId?">
                        <EmployeesContainer />
                      </Route>
                      <Route exact path="/export">
                        <ExportContainer />
                      </Route>
                      <Redirect path="/" to="/employees" />
                    </Switch>
                  </div>
                </div>
              )}
              {!isPossiblySignedIn && (
                <Login onLogin={() => setIsPossiblySignedIn(true)} />
              )}
            </div>
          </ActiveYearProvider>
        </IntlProvider>
      </QueryClientProvider>
    </Router>
  );
}

export default App;
