import nl from '../translations/nl';

const t = (key) => {
  return nl[key];
};

const useFormatMessage = () => {
  return t;
};

export default useFormatMessage;
