import React from "react";
import useActiveYear from "../hooks/useActiveYear";
import ExportButton from "./ExportButton";
import YearDropdown from "./YearDropdown";

const Header = () => {
  return (
    <div className="w-full border-b px-4 py-2 flex gap-x-2 justify-end print:hidden">
      <YearDropdown />
      <ExportButton />
    </div>
  );
};

export default Header;
