import { useLocation, useParams as RRDUseParams } from "react-router-dom";

const useParams = () => ({
  ...RRDUseParams(),
  ...Object.fromEntries([
    ...new URLSearchParams(useLocation().search).entries(),
  ]),
});

export default useParams;
