import { useCallback } from "react";
import useDocument from "./useDocument";

const useExport = () => {
  const download = useDocument();

  return useCallback(
    ({ employeeId, year, monthIndex }) => {
      const params = new URLSearchParams(
        Object.fromEntries(
          Object.entries({
            employee_id: employeeId,
            year,
            month: monthIndex + 1,
          }).filter(([_, v]) => v !== undefined && v !== null)
        )
      );

      return download(`/export?${params.toString()}`, "export.pdf");
    },
    [download]
  );
};

export default useExport;
