import React, { useEffect } from "react";
import { focusManager } from "react-query";
import { useQuery } from "react-query";
import useActiveYear from "../../hooks/useActiveYear";
import useParams from "../../hooks/useParams";
import EmployeeDetailContainer from "../EmployeeDetail/EmployeeDetailContainer";

const ExportContainer = () => {
  const { data: _employees } = useQuery(`/employees`);
  const params = useParams();

  const { year, monthIndex, setYear, setMonthIndex } = useActiveYear();

  useEffect(() => {
    focusManager.setFocused(false);

    return () => focusManager.setFocused(undefined);
  }, []);

  useEffect(() => {
    if (params.year && year !== Number(params.year)) {
      setYear(Number(params.year));
    }
    if (params.month && monthIndex !== Number(params.month) - 1) {
      setMonthIndex(Number(params.month) - 1);
    }
  }, [year, monthIndex, params, setYear, setMonthIndex]);

  if (!_employees) {
    return null;
  }

  const employees = params.employee_id
    ? _employees.filter(
        (employee) => employee.id === Number(params.employee_id)
      )
    : _employees;

  if (
    (params.year && year !== Number(params.year)) ||
    (params.month && monthIndex !== Number(params.month) - 1)
  ) {
    return null;
  }

  return (
    <div className="overflow-auto">
      {employees.map((employee) => (
        <div className="break-inside-avoid" key={employee.id}>
          <EmployeeDetailContainer employeeId={employee.id} canEdit={false} />
        </div>
      ))}
    </div>
  );
};

export default ExportContainer;
