import { ACTIVITY_TYPES } from "../utils/activities/createSummaryTable";

const nl = {
  // Global
  "global.company_name": "Autobedrijf Decleir",
  "global.close": "Sluit",

  // Navigation
  "navigation.items.employees": "Werknemers",

  // Employees
  "employees.title": "Werknemers",
  "employees.title.search": "Zoek een werknemer op voornaam",
  "employees.type.bediende": "Bediende",
  "employees.type.arbeider": "Arbeider",

  "employee-detail.label.type": "Type",
  "employee-detail.label.joint_committee": "Paritair comité",
  "employee-detail.label.employed_at": "In dienst",
  "employee-detail.label.seniority": "Anciëntiteit",

  "employee-detail.download": "Download",
  "employee-detail.range.previous": "Vorige maand",
  "employee-detail.range.today": "Vandaag",
  "employee-detail.range.next": "Volgende maand",

  "employee-detail.balances.label": "Saldo's",
  "employee-detail.balances.befor_month": "Voor maand",
  "employee-detail.balances.after_month": "Na maand",

  [`activity-types.${ACTIVITY_TYPES.GEWERKT}`]: "Gewerkt",
  [`activity-types.${ACTIVITY_TYPES.OVERUREN}`]: "Overuren",
  [`activity-types.${ACTIVITY_TYPES.ADV}`]: "ADV",
  [`activity-types.${ACTIVITY_TYPES.VERLOF}`]: "Congé / Verlof",
  [`activity-types.${ACTIVITY_TYPES.ZIEKTE}`]: "Ziekte",
  [`activity-types.${ACTIVITY_TYPES.FEESTDAG}`]: "Feestdag",
};

export default nl;
