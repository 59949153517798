import React from "react";
import c from "../utils/c";

const STYLES = {
  extraSmall: "h-6 w-6 text-xs",
  small: "h-8 w-8 text-sm",
  base: "h-10 w-10 text-base",
  large: "h-12 w-12 text-lg",
  extraLarge: "h-12 w-12 text-lg",
};

const compute = (styles, defaultStyle) => {
  return STYLES[
    Object.entries(styles)
      .filter(([, v]) => Boolean(v))
      .map(([k]) => k)[0] || defaultStyle
  ];
};

const Avatar = ({ extraSmall, small, large, extraLarge, children }) => {
  return (
    <span
      className={c(
        "inline-flex items-center justify-center rounded-full bg-gray-500",
        compute({ extraSmall, small, large, extraLarge }, "base")
      )}
    >
      <span className="font-medium leading-none text-white">{children}</span>
    </span>
  );
};

export default Avatar;
