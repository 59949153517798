import { useCallback } from "react";
import { saveAs } from "file-saver";

import { api } from "../utils/api";

const useDocument = () => {
  return useCallback((url, filename) => {
    return api
      .post(url)
      .then((response) => response.blob())
      .then((response) => saveAs(response, filename));
  }, []);
};

export default useDocument;
