import React, { useMemo } from "react";
import { useLocalStorageState } from "../hooks/useLocalStorageState";

export const ActiveYearContext = React.createContext({});

const ActiveYearProvider = ({ children }) => {
  const [year, setYear] = useLocalStorageState(
    "year",
    new Date().getFullYear()
  );
  const [monthIndex, setMonthIndex] = useLocalStorageState(
    "month",
    new Date().getMonth()
  );

  const context = useMemo(() => {
    return {
      year,
      monthIndex,
      setYear,
      setMonthIndex,
    };
  }, [monthIndex, year, setYear, setMonthIndex]);

  return (
    <ActiveYearContext.Provider value={context}>
      {children}
    </ActiveYearContext.Provider>
  );
};

export default ActiveYearProvider;
