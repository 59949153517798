import { isWithinInterval } from "date-fns";
import convertDate from "./convertDate";
import createSummaryTable, { ACTIVITY_TYPES } from "./createSummaryTable";

const calculate = (year, _activities, paidOvertime, notes, initialBalances) => {
  const monthIDs = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

  const paidOvertimePerMonth = Object.fromEntries(
    paidOvertime.map((overtime) => {
      const date = convertDate(overtime.date);
      return [
        date.getMonth(),
        {
          ...overtime,
          date,
          hours_paid: Number(overtime.hours_paid),
        },
      ];
    })
  );

  const notePerMonth = Object.fromEntries(
    notes.map((note) => {
      const date = convertDate(note.date);
      return [date.getMonth(), note];
    })
  );

  const activities = _activities.map((activity) => ({
    ...activity,
    date: convertDate(activity.date),
    originalDate: activity.date,
    number_of_worked_hours: Number(activity.number_of_worked_hours),
    number_of_working_hours_in_day: Number(
      activity.number_of_working_hours_in_day
    ),
  }));

  const cumulativeBalances = [
    {
      [ACTIVITY_TYPES.GEWERKT]: 0,
      [ACTIVITY_TYPES.OVERUREN]: Number(
        initialBalances[ACTIVITY_TYPES.OVERUREN] || 0
      ),
      [ACTIVITY_TYPES.ADV]: Number(initialBalances[ACTIVITY_TYPES.ADV] || 0),
      [ACTIVITY_TYPES.VERLOF]: Number(
        initialBalances[ACTIVITY_TYPES.VERLOF] || 0
      ),
      [ACTIVITY_TYPES.ZIEKTE]: 0,
      [ACTIVITY_TYPES.FEESTDAG]: 0,
      [ACTIVITY_TYPES.OVERUREN_BETAALD]: 0,
    },
  ];

  return Object.fromEntries(
    monthIDs.map((monthID) => {
      const start = new Date(year, monthID, 1);
      const end = new Date(year, monthID + 1, 0);
      const range = { start, end };

      const filteredActivities = activities.filter(({ date }) => {
        return isWithinInterval(date, range);
      });

      const {
        activities: filteredActivitiesWithBalances,
        balances,
      } = createSummaryTable(filteredActivities, range);

      cumulativeBalances.push({
        [ACTIVITY_TYPES.GEWERKT]:
          cumulativeBalances[monthID][ACTIVITY_TYPES.GEWERKT] +
          balances[ACTIVITY_TYPES.GEWERKT],
        [ACTIVITY_TYPES.OVERUREN]:
          cumulativeBalances[monthID][ACTIVITY_TYPES.OVERUREN] +
          balances[ACTIVITY_TYPES.OVERUREN] -
          (paidOvertimePerMonth[monthID]?.hours_paid || 0),
        [ACTIVITY_TYPES.ADV]:
          cumulativeBalances[monthID][ACTIVITY_TYPES.ADV] -
          balances[ACTIVITY_TYPES.ADV],
        [ACTIVITY_TYPES.VERLOF]:
          cumulativeBalances[monthID][ACTIVITY_TYPES.VERLOF] -
          balances[ACTIVITY_TYPES.VERLOF],
        [ACTIVITY_TYPES.ZIEKTE]:
          cumulativeBalances[monthID][ACTIVITY_TYPES.ZIEKTE] +
          balances[ACTIVITY_TYPES.ZIEKTE],
        [ACTIVITY_TYPES.FEESTDAG]:
          cumulativeBalances[monthID][ACTIVITY_TYPES.FEESTDAG] +
          balances[ACTIVITY_TYPES.FEESTDAG],
        [ACTIVITY_TYPES.OVERUREN_BETAALD]:
          cumulativeBalances[monthID][ACTIVITY_TYPES.OVERUREN_BETAALD] +
          (paidOvertimePerMonth[monthID]?.hours_paid || 0),
      });

      return [
        monthID,
        {
          monthID,
          start,
          end,
          activities: filteredActivitiesWithBalances,
          paidOvertime: paidOvertimePerMonth[monthID]?.hours_paid || 0,
          note: notePerMonth[monthID]?.note || "",
          monthBalance: balances,
          beforeMonthBalance: cumulativeBalances[monthID],
          afterMonthBalance: cumulativeBalances[monthID + 1],
        },
      ];
    })
  );
};

export default calculate;
