import React, { useMemo } from "react";
import { differenceInYears, differenceInMonths, addYears } from "date-fns";

const Seniority = ({ employedAt: _employedAt, until }) => {
  const { years, months } = useMemo(() => {
    let employedAt = new Date(_employedAt.getTime());
    const years = differenceInYears(until, employedAt);
    employedAt = addYears(employedAt, years);
    const months = differenceInMonths(until, employedAt);

    return { years, months };
  }, [_employedAt, until]);

  return (
    <div>
      {years} Jr {months} Md
    </div>
  );
};

export default Seniority;
