import React from "react";

import Avatar from "../../../components/Avatar";
import useFormatMessage from "../../../hooks/useFormatMessage";

const AsideItem = ({ employee }) => {
  const t = useFormatMessage();

  return (
    <button className="w-full px-6 py-5 flex items-center space-x-3 text-left hover:bg-gray-50">
      <div className="flex-shrink-0">
        <Avatar>
          {employee.first_name.substr(0, 1)}
          {employee.last_name.substr(0, 1)}
        </Avatar>
      </div>
      <div>
        <p className="text-sm font-medium text-gray-900">
          {employee.first_name} {employee.last_name}
        </p>
        <p className="text-sm text-gray-500 truncate">
          {t(`employees.type.${employee.type}`)}
        </p>
      </div>
    </button>
  );
};

export default AsideItem;
