import { eachDayOfInterval } from "date-fns";
import convertDate from "./convertDate";
import getActivitiesInRange from "./getActivitiesInRange";

// see ActivityTypeEnum.php
export const ACTIVITY_TYPES = {
  GEWERKT: "gewerkt",
  OVERUREN: "overuren",
  OVERUREN_BETAALD: "overuren_betaald",
  ADV: "adv",
  VERLOF: "verlof",
  ZIEKTE: "ziekte",
  FEESTDAG: "feestdag",
};

const createSummaryTable = (activities, range) => {
  const balances = {
    [ACTIVITY_TYPES.GEWERKT]: 0,
    [ACTIVITY_TYPES.OVERUREN]: 0,
    [ACTIVITY_TYPES.ADV]: 0,
    [ACTIVITY_TYPES.VERLOF]: 0,
    [ACTIVITY_TYPES.ZIEKTE]: 0,
    [ACTIVITY_TYPES.FEESTDAG]: 0,
  };

  const activitiesWithSummaries = eachDayOfInterval(range).map((day) => {
    const activity = activities.find(({ date }) => {
      return (
        date.getDate() === day.getDate() &&
        date.getMonth() === day.getMonth() &&
        date.getFullYear() === day.getFullYear()
      );
    });

    if (!activity) {
      return {
        date: day,
      };
    }

    const activityWithSummary = {
      ...activity,
      date: day,
      [ACTIVITY_TYPES.GEWERKT]:
        activity.type === ACTIVITY_TYPES.GEWERKT
          ? activity.number_of_worked_hours
          : 0,
      [ACTIVITY_TYPES.OVERUREN]:
        activity.type === ACTIVITY_TYPES.GEWERKT
          ? activity.number_of_worked_hours -
            activity.number_of_working_hours_in_day
          : 0,
      [ACTIVITY_TYPES.ADV]:
        activity.type === ACTIVITY_TYPES.ADV
          ? activity.number_of_working_hours_in_day
          : 0,
      [ACTIVITY_TYPES.VERLOF]:
        activity.type === ACTIVITY_TYPES.VERLOF
          ? activity.number_of_working_hours_in_day
          : 0,
      [ACTIVITY_TYPES.ZIEKTE]:
        activity.type === ACTIVITY_TYPES.ZIEKTE
          ? activity.number_of_working_hours_in_day
          : 0,
      [ACTIVITY_TYPES.FEESTDAG]:
        activity.type === ACTIVITY_TYPES.FEESTDAG
          ? activity.number_of_working_hours_in_day
          : 0,
    };

    balances[ACTIVITY_TYPES.GEWERKT] +=
      activityWithSummary[ACTIVITY_TYPES.GEWERKT];
    balances[ACTIVITY_TYPES.OVERUREN] +=
      activityWithSummary[ACTIVITY_TYPES.OVERUREN];
    balances[ACTIVITY_TYPES.ADV] += activityWithSummary[ACTIVITY_TYPES.ADV];
    balances[ACTIVITY_TYPES.VERLOF] +=
      activityWithSummary[ACTIVITY_TYPES.VERLOF];
    balances[ACTIVITY_TYPES.ZIEKTE] +=
      activityWithSummary[ACTIVITY_TYPES.ZIEKTE];
    balances[ACTIVITY_TYPES.FEESTDAG] +=
      activityWithSummary[ACTIVITY_TYPES.FEESTDAG];

    return activityWithSummary;
  });

  return {
    balances,
    activities: activitiesWithSummaries,
  };
};

export default createSummaryTable;
