export const getToken = () => {
  return (
    window.localStorage.getItem("jwt.token") ||
    document.cookie
      .split("; ")
      .find((row) => row.startsWith("TEMP-AUTHORIZATION"))
      ?.split("=")[1]
  );
};

export const setToken = (newToken) => {
  window.localStorage.setItem("jwt.token", newToken);
};

export const removeToken = () => {
  window.localStorage.removeItem("jwt.token");
};

const handleRequest = (response) => {
  if (response && response.status === 401) {
    if (getToken()) {
      removeToken();
      window.location.href = "/";
    }

    throw response;
  }

  if (!response.ok) {
    throw response;
  }

  if (response.headers.get("Content-Type") === "application/json") {
    return response.json();
  }

  return response;
};

const request = (endpoint, method, { body } = {}) =>
  fetch(`${process.env.REACT_APP_API_URL}${endpoint}`, {
    method,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
    body: body ? JSON.stringify(body) : undefined,
  }).then(handleRequest);

export const api = {
  get: (endpoint, props) => request(endpoint, "GET", props),
  post: (endpoint, props) => request(endpoint, "POST", props),
  put: (endpoint, props) => request(endpoint, "PUT", props),
  del: (endpoint, props) => request(endpoint, "DELETE", props),
};
