import React, { useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import useActiveYear from "../../hooks/useActiveYear";

import Employees from "./Employees";

const EmployeesContainer = () => {
  const [filter, setFilter] = useState("");

  const { year } = useActiveYear();
  const { employeeId } = useParams();

  const { data: employees } = useQuery(
    `/employees?filter[first_name]=${filter}`,
    {
      keepPreviousData: true,
    }
  );

  if (!employees) {
    return null;
  }

  return (
    <Employees
      employees={employees}
      year={year}
      employeeId={employeeId}
      setFilter={setFilter}
      filter={filter}
    />
  );
};

export default EmployeesContainer;
