import React, { useState } from "react";
import useActiveYear from "../hooks/useActiveYear";
import useExport from "../hooks/useExport";
import Button from "./Button";
import Spinner from "./Spinner";

const monthNames = [
  "januari",
  "februari",
  "maart",
  "april",
  "mei",
  "juni",
  "juli",
  "augustus",
  "september",
  "oktober",
  "november",
  "december",
];

const ExportButton = ({ className, employeeId, children, isIcon }) => {
  const [loading, setLoading] = useState(false);
  const download = useExport();
  const { monthIndex, year } = useActiveYear();

  return (
    <Button
      className={className}
      disabled={loading}
      onClick={() => {
        setLoading(true);
        download({ monthIndex, year, employeeId })
          .then(() => {
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      }}
      isIcon={isIcon}
    >
      {isIcon && loading ? <Spinner /> : undefined}
      {isIcon && !loading ? children : undefined}
      {!isIcon && loading ? "Downloading..." : undefined}
      {!isIcon && !loading
        ? `Export ${monthNames[monthIndex]} ${year}`
        : undefined}
    </Button>
  );
};

export default ExportButton;
